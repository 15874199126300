.new-account-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.new-account-frame192010-data-catalog {
  width: 100%;
  height: 1288px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 22px;
  background-color: rgba(255, 255, 255, 1);
}
.new-account-text {
  gap: 40px;
  top: 205px;
  left: 871px;
  width: 729px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-frame1171274919 {
  gap: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-frame1171274914 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-text01 {
  color: var(--dl-color-default-black2);
  width: 539px;
  height: auto;
  font-size: 40px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 129.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text03 {
  color: rgba(16, 24, 33, 1);
  width: 729px;
  height: auto;
  font-size: 17px;
  font-style: Light;
  text-align: left;
  font-family: Rubik;
  font-weight: 300;
  line-height: 170.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-frame1171274918 {
  gap: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-frame1171274915 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-text05 {
  color: var(--dl-color-default-black2);
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text07 {
  color: rgba(16, 24, 33, 1);
  width: 729px;
  height: auto;
  font-size: 17px;
  font-style: Light;
  text-align: left;
  font-family: Rubik;
  font-weight: 300;
  line-height: 170.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-frame1171274916 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-text09 {
  color: var(--dl-color-default-black2);
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text11 {
  color: rgba(16, 24, 33, 1);
  width: 729px;
  height: auto;
  font-size: 17px;
  font-style: Light;
  text-align: left;
  font-family: Rubik;
  font-weight: 300;
  line-height: 170.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-frame1171274917 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-text13 {
  color: var(--dl-color-default-black2);
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text15 {
  color: rgba(16, 24, 33, 1);
  width: 729px;
  height: auto;
  font-size: 17px;
  font-style: Light;
  text-align: left;
  font-family: Rubik;
  font-weight: 300;
  line-height: 170.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text17 {
  color: rgba(16, 24, 33, 1);
  width: 729px;
  height: auto;
  font-size: 17px;
  font-style: Light;
  text-align: left;
  font-family: Rubik;
  font-weight: 300;
  line-height: 170.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-pictures {
  top: 205px;
  left: 100px;
  width: 451.67877197265625px;
  height: 426px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-shapebg {
  top: 0px;
  left: 13px;
  width: 426px;
  height: 426px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-ellipse246 {
  top: 25.03432846069336px;
  left: 25.034442901611328px;
  width: 377px;
  height: 377px;
  opacity: 0.40;
  position: absolute;
}
.new-account-ellipse247 {
  top: 0px;
  flex: 1;
  left: 0px;
  width: 426px;
  height: 426px;
  opacity: 0.20;
  position: absolute;
}
.new-account-group35559 {
  top: 52px;
  left: 0px;
  width: 451.67877197265625px;
  height: 337.737548828125px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-group10 {
  top: 0px;
  left: 0px;
  width: 387.69232177734375px;
  height: 297.5565490722656px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(255, 255, 255, 1);
}
.new-account-rectangle338 {
  top: 38.00895309448242px;
  left: -0.000028722426577587612px;
  width: 388px;
  height: 260px;
  position: absolute;
  border-radius: 0 0 13.031673431396484px 13.031673431396484px;
}
.new-account-group6 {
  top: 66.24424743652344px;
  left: 139.0045166015625px;
  width: 46.696834564208984px;
  height: 8.687782287597656px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(226, 232, 240, 1);
}
.new-account-group8 {
  top: 14.117639541625977px;
  left: 17.37548828125px;
  width: 218.28054809570312px;
  height: 10.85972785949707px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-rectangle339 {
  top: -0.00003590303458622657px;
  left: 133.57473754882812px;
  width: 85px;
  height: 11px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-group7 {
  top: 1.0859016180038452px;
  left: -1.1368683772161603e-13px;
  width: 39.09502410888672px;
  height: 8.687782287597656px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-ellipse3 {
  top: 0px;
  left: 0px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.new-account-ellipse4 {
  top: -0.00003590303458622657px;
  left: 15.203699111938477px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.new-account-ellipse5 {
  top: -0.00003590303458622657px;
  left: 30.407283782958984px;
  width: 9px;
  height: 9px;
  position: absolute;
}
.new-account-group9 {
  top: 94.47954559326172px;
  left: 17.37548828125px;
  width: 193.3031768798828px;
  height: 173.75564575195312px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-group2 {
  top: 0px;
  left: 0px;
  width: 193.3031768798828px;
  height: 52.12669372558594px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(255, 255, 255, 1);
}
.new-account-ellipse6 {
  top: 10.859769821166992px;
  left: 13.031709671020508px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.new-account-group1 {
  top: 16.28957176208496px;
  left: 52.12678146362305px;
  width: 69.50225830078125px;
  height: 19.54751205444336px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-rectangle344 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 7px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-rectangle345 {
  top: 15.203605651855469px;
  left: 0.00001077091019396903px;
  width: 60px;
  height: 4px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-group3 {
  top: 60.81461715698242px;
  left: -1.1368683772161603e-13px;
  width: 193.3031768798828px;
  height: 52.12669372558594px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(255, 255, 255, 1);
}
.new-account-ellipse61 {
  top: 10.859647750854492px;
  left: 13.031709671020508px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.new-account-group11 {
  top: 16.289566040039062px;
  left: 52.12678146362305px;
  width: 69.50225830078125px;
  height: 19.54751205444336px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-rectangle3441 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 7px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-rectangle3451 {
  top: 15.203598976135254px;
  left: 0.00001077091019396903px;
  width: 60px;
  height: 4px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-group4 {
  top: 121.62907409667969px;
  left: -1.1368683772161603e-13px;
  width: 193.3031768798828px;
  height: 52.12669372558594px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  background-color: rgba(255, 255, 255, 1);
}
.new-account-ellipse62 {
  top: 10.859763145446777px;
  left: 13.031709671020508px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.new-account-group12 {
  top: 16.289566040039062px;
  left: 52.12678146362305px;
  width: 69.50225830078125px;
  height: 19.54751205444336px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-rectangle3442 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 7px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-rectangle3452 {
  top: 15.203713417053223px;
  left: 0.00001077091019396903px;
  width: 60px;
  height: 4px;
  position: absolute;
  border-radius: 54.298641204833984px;
}
.new-account-rectangle347 {
  right: 6px;
  width: 298px;
  bottom: 2px;
  height: 139px;
  position: absolute;
  border-radius: 13.031673431396484px;
}
.new-account-image68 {
  top: 232px;
  left: 233px;
  width: 162px;
  height: 33px;
  position: absolute;
}
.new-account-input {
  gap: 5.34632682800293px;
  top: 94.47954559326172px;
  left: 17.37548828125px;
  display: flex;
  padding: 0 7.1284356117248535px;
  overflow: hidden;
  position: absolute;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(26, 143, 247, 1);
  border-style: solid;
  border-width: 0.44552722573280334px;
  border-radius: 7.1284356117248535px;
  justify-content: center;
  background-color: rgba(236, 242, 248, 1);
}
.new-account-frame1171274906 {
  gap: 0.8910544514656067px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.new-account-internet-networkloud-storage-upload {
  width: 14.256871223449707px;
  height: 14.256871223449707px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.new-account-group {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 14.256871223449707px;
  height: 14.256871223449707px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-group1 {
  top: 2.970176935195923px;
  left: 1.1880816221237183px;
  width: 11.88072681427002px;
  height: 8.316509246826172px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-path {
  top: 0px;
  left: 0px;
  width: 12px;
  height: 8px;
  position: absolute;
}
.new-account-path1 {
  top: 3.2671968936920166px;
  left: 4.653071403503418px;
  width: 3px;
  height: 1px;
  position: absolute;
}
.new-account-path2 {
  top: 3.2671968936920166px;
  left: 5.643325328826904px;
  width: 1px;
  height: 3px;
  position: absolute;
}
.new-account-text19 {
  color: var(--dl-color-default-black2);
  height: auto;
  font-size: 7.1284356117248535px;
  font-style: Light;
  text-align: left;
  font-family: Poppins;
  font-weight: 300;
  line-height: 129.99999523162842%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-rectangle346 {
  top: 59.7283935546875px;
  left: 161.8099365234375px;
  width: 211px;
  height: 122px;
  position: absolute;
  border-radius: 13.031673431396484px;
}
.new-account-group35557 {
  top: 216.1085662841797px;
  left: 17.37548828125px;
  width: 210.67872619628906px;
  height: 121.62896728515625px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-group35555 {
  top: 0px;
  left: 0px;
  width: 210.67872619628906px;
  height: 121.62896728515625px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-rectangle348 {
  top: 0px;
  left: 0px;
  width: 211px;
  height: 122px;
  position: absolute;
  border-radius: 13.031673431396484px;
}
.new-account-group35556 {
  top: 24.97740936279297px;
  left: 48.868797302246094px;
  width: 112.27288055419922px;
  height: 71.51324462890625px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-image67 {
  top: 0px;
  left: 0px;
  width: 112px;
  height: 52px;
  position: absolute;
}
.new-account-vector {
  top: 57.5565185546875px;
  left: 19.213485717773438px;
  width: 75px;
  height: 14px;
  position: absolute;
}
.new-account-group35558 {
  top: 56.4705810546875px;
  left: 17.37548828125px;
  width: 104.25338745117188px;
  height: 26.731637954711914px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-logodatasimpl4 {
  top: 0px;
  left: 0px;
  width: 52.12669372558594px;
  height: 19.204633712768555px;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
}
.new-account-text21 {
  top: 15.454044342041016px;
  left: 14.348402976989746px;
  color: rgba(26, 143, 247, 1);
  height: auto;
  position: absolute;
  font-size: 2.375030755996704px;
  font-style: Medium;
  text-align: left;
  font-family: Montserrat;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text23 {
  top: 15.454044342041016px;
  left: 29.09777069091797px;
  color: rgba(26, 143, 247, 1);
  height: auto;
  position: absolute;
  font-size: 2.375030755996704px;
  font-style: Medium;
  text-align: left;
  font-family: Montserrat;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-text25 {
  top: 15.454044342041016px;
  left: 40.91543960571289px;
  color: rgba(26, 143, 247, 1);
  height: auto;
  position: absolute;
  font-size: 2.375030755996704px;
  font-style: Medium;
  text-align: left;
  font-family: Montserrat;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-vector01 {
  top: 5.146944046020508px;
  left: 14.595358848571777px;
  width: 2px;
  height: 10px;
  position: absolute;
}
.new-account-vector02 {
  top: 1.3998161554336548px;
  left: 49.702491760253906px;
  width: 2px;
  height: 14px;
  position: absolute;
}
.new-account-vector03 {
  top: 4.537454128265381px;
  left: 19.330652236938477px;
  width: 16px;
  height: 10px;
  position: absolute;
}
.new-account-vector04 {
  top: 4.463235378265381px;
  left: 37.044864654541016px;
  width: 11px;
  height: 15px;
  position: absolute;
}
.new-account-vector05 {
  top: 0.2880284786224365px;
  left: 17.988452911376953px;
  width: 1px;
  height: 4px;
  position: absolute;
}
.new-account-vector06 {
  top: 1.2020909786224365px;
  left: 14.113625526428223px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.new-account-vector07 {
  top: 1.1938189268112183px;
  left: 19.450138092041016px;
  width: 3px;
  height: 3px;
  position: absolute;
}
.new-account-vector08 {
  top: -2.2737367544323206e-13px;
  left: 10.313074111938477px;
  width: 3px;
  height: 5px;
  position: absolute;
}
.new-account-vector09 {
  top: 7.073988914489746px;
  left: 4.713005542755127px;
  width: 9px;
  height: 6px;
  position: absolute;
}
.new-account-vector10 {
  top: 2.267922878265381px;
  left: 0.0024701287038624287px;
  width: 9px;
  height: 16px;
  position: absolute;
}
.new-account-form-data {
  top: 671px;
  left: 100px;
  width: 460px;
  height: 468px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.new-account-container1 {
  flex: 0;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.new-account-text27 {
  color: var(--dl-color-text-dark2);
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.new-account-select {
  width: 460px;
  margin-top: var(--dl-space-space-unit);
  line-height: 1.15;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: none;
  text-decoration: none;
  background-color: #F8F8F9;
}
.new-account-text29 {
  color: var(--dl-color-text-dark2);
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.new-account-textinput {
  align-self: flex-start;
}
.new-account-container2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
}
.new-account-checkbox {
  align-self: center;
  margin-top: 0px;
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.new-account-text30 {
  color: rgba(16, 24, 33, 1);
  height: auto;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Rubik;
  font-weight: 400;
  line-height: 170.00000476837158%;
  font-stretch: normal;
  text-decoration: none;
}
.new-account-container3 {
  top: 300px;
  left: 0px;
  width: 460.0000228881836px;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: space-between;
}
.new-account-button {
  top: 0px;
  left: 0px;
  margin: auto;
  position: absolute;
  border-width: 1;
  border-radius: 23;
}
.new-account-button1 {
  top: 0px;
  color: #ffffff;
  right: 0px;
  margin: auto;
  position: absolute;
  border-color: #1a8ff7;
  border-width: 1px;
  border-radius: 23px;
  justify-content: center;
  background-color: #1a8ff7;
}
.new-account-header {
  top: 0px;
  left: 0px;
  width: var(--dl-size-size-maxwidth);
  height: auto;
  display: flex;
  position: absolute;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.new-account-logodatasimpl021 {
  height: 20px;
  align-self: center;
  margin-right: 50px;
  margin-top: 10px;
  margin-left: 50px;
}
@media(max-width: 1440px) {
  .new-account-frame192010-data-catalog {
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
  }
  .new-account-text {
    left: 650px;
  }
  .new-account-pictures {
    left: 100px;
  }
  .new-account-rectangle347 {
    top: 180px;
    left: 170px;
    width: 334px;
    height: 205px;
  }
  .new-account-image68 {
    right: -49px;
    bottom: 46px;
  }
  .new-account-rectangle346 {
    top: 66px;
    left: 100px;
    width: 249px;
    height: 190px;
  }
  .new-account-form-data {
    left: 100px;
  }
}
@media(max-width: 1200px) {
  .new-account-text03 {
    width: 400;
  }
  .new-account-text07 {
    width: 400;
  }
  .new-account-text11 {
    width: 400;
  }
  .new-account-text15 {
    width: 400;
  }
  .new-account-text17 {
    width: 400;
  }
}
@media(max-width: 767px) {
  .new-account-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .new-account-header {
    padding: var(--dl-space-space-unit);
  }
}
