.workspace-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.workspace-container01 {
  width: 100%;
  height: 779px;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-start;
}
.workspace-container02 {
  top: 595px;
  left: 513px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.workspace-rectangle-inbound {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-icon {
  fill: #D9D9D9;
  width: 40px;
  height: 40px;
}
.workspace-container-left-down {
  top: 443px;
  left: 145px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.workspace-rectangle-inbound1 {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-icon02 {
  top: 68px;
  fill: #D9D9D9;
  left: 60px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.workspace-icon04 {
  top: 39px;
  fill: #D9D9D9;
  left: 60px;
  width: 109px;
  height: 114px;
  position: absolute;
}
.workspace-container03 {
  top: 64px;
  left: 152px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  justify-content: flex-start;
}
.workspace-rectangle-inbound2 {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-icon06 {
  top: 37px;
  fill: #D9D9D9;
  left: 53px;
  width: 100px;
  height: 100px;
  position: absolute;
}
.workspace-icon08 {
  top: 78.77838134765625px;
  left: 89.77835083007812px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.workspace-container04 {
  top: 595px;
  left: 908px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.workspace-rectangle-inbound3 {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-icon10 {
  fill: #D9D9D9;
  width: 40px;
  height: 40px;
}
.workspace-icon12 {
  top: 61px;
  left: 82px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.workspace-container-middle {
  top: 262px;
  left: 687px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.workspace-rectangle-inbound4 {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-icon14 {
  width: 40px;
  height: 40px;
  position: absolute;
}
.workspace-icon16 {
  fill: #D9D9D9;
  width: 40px;
  height: 40px;
}
.workspace-container05 {
  top: 61px;
  left: 1245px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  justify-content: flex-start;
}
.workspace-rectangle-inbound5 {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-icon18 {
  top: 43px;
  fill: #D9D9D9;
  left: 53px;
  width: 100px;
  height: 100px;
  position: absolute;
}
.workspace-icon20 {
  top: 81.82173156738281px;
  left: 86.82177734375px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.workspace-container06 {
  top: 443px;
  left: 1245px;
  width: 204px;
  height: 184px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  justify-content: flex-start;
}
.workspace-icon22 {
  top: 55px;
  fill: #D9D9D9;
  left: 57px;
  width: 40px;
  height: 40px;
  position: absolute;
}
.workspace-icon24 {
  top: 38px;
  fill: #D9D9D9;
  left: 62px;
  width: 109px;
  height: 114px;
  position: absolute;
}
.workspace-rectangle-inbound6 {
  top: 0px;
  left: 0px;
  width: 204px;
  height: 184px;
  position: absolute;
}
.workspace-rectangle35 {
  left: -301px;
  width: 33px;
  bottom: 79px;
  height: 30px;
  position: absolute;
}
.workspace-container07 {
  top: 338px;
  left: 370px;
  width: 117px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3501 {
  right: -621px;
  width: 33px;
  bottom: 0px;
  height: 30px;
  position: absolute;
}
.workspace-rectangle3502 {
  right: -668px;
  width: 33px;
  bottom: 0px;
  height: 30px;
  position: absolute;
}
.workspace-rectangle3503 {
  right: -718px;
  width: 33px;
  bottom: 0px;
  height: 30px;
  position: absolute;
}
.workspace-rectangle3504 {
  top: 0px;
  left: 48px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-rectangle3505 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-container08 {
  right: -347px;
  width: 33px;
  bottom: -372px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3506 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon26 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container09 {
  right: -347px;
  width: 33px;
  bottom: -330px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3507 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon28 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container10 {
  right: -347px;
  width: 33px;
  bottom: -287px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3508 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon30 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container11 {
  top: 292px;
  right: -879px;
  width: 33px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3509 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon32 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container12 {
  right: -880px;
  width: 33px;
  bottom: -332px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3510 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon34 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container13 {
  right: -880px;
  width: 33px;
  bottom: -373px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3511 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon36 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container14 {
  left: -140px;
  width: 33px;
  bottom: -373px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3512 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon38 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container15 {
  left: -140px;
  width: 33px;
  bottom: -332px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3513 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon40 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container16 {
  top: 292px;
  left: -140px;
  width: 33px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3514 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon42 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container17 {
  top: 292px;
  left: -140px;
  width: 33px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3515 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-icon44 {
  top: 4px;
  fill: #D9D9D9;
  left: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  border-color: #ffffff;
  border-width: 0px;
}
.workspace-container18 {
  top: -242px;
  left: -205px;
  width: 33px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3516 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-text {
  left: 5px;
  color: #000000;
  bottom: 2px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-container19 {
  right: -68px;
  width: 33px;
  bottom: -369px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3517 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-text01 {
  left: 5px;
  color: #000000;
  bottom: 2px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-container20 {
  right: -68px;
  width: 33px;
  bottom: -275px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3518 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-text02 {
  left: 5px;
  color: #000000;
  bottom: 2px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-container21 {
  right: -634px;
  width: 33px;
  bottom: -330px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3519 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-text03 {
  left: 5px;
  color: #000000;
  bottom: 2px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-container22 {
  top: -248px;
  right: -800px;
  width: 33px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  justify-content: flex-start;
}
.workspace-rectangle3520 {
  top: 0px;
  left: 0px;
  width: 33px;
  height: 30px;
  position: absolute;
}
.workspace-text04 {
  left: 5px;
  color: #000000;
  bottom: 2px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-rectangle3521 {
  right: -15px;
  width: 33px;
  bottom: 0px;
  height: 30px;
  position: absolute;
}
.workspace-text05 {
  top: 79px;
  left: 221px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text06 {
  left: 400px;
  color: rgb(34, 141, 241);
  bottom: 47px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text09 {
  left: 399px;
  color: rgb(34, 141, 241);
  bottom: 140px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text12 {
  left: 1138px;
  color: rgb(34, 141, 241);
  bottom: 80px;
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text17 {
  top: 561px;
  left: 774px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text18 {
  top: 295px;
  left: 399px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text19 {
  top: 297px;
  left: 1110px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text20 {
  top: 214px;
  left: 242px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text21 {
  top: 212px;
  left: 1335px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
.workspace-text22 {
  top: 78px;
  left: 1303px;
  color: rgb(34, 141, 241);
  position: absolute;
  font-size: 20px;
  font-weight: 700;
}
@media(max-width: 1440px) {
  .workspace-rectangle-inbound1 {
    left: 152px;
  }
  .workspace-rectangle3521 {
    top: 338px;
    left: 454px;
  }
}
@keyframes reverse-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.animate-reverse-spin {
  animation: reverse-spin 1s linear infinite;
}
.background-image {
   
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 120vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
  
}
.middle_icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
 
}

.rotate-45 {
  animation: rotate 3s linear infinite;
}

.rotate-90 {
  animation: rotate 3s linear infinite reverse;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 1300px) {
  .direction {
    padding-top: 12px;
    padding-bottom: 12px;
flex-direction: column;    
  }
    .statusContainer{
margin-top:4px ;
    }
}