.amplify-button {
    /* background-color: #007bff; */
    color: black;
    /* padding: 10px 20px; */
    /* border: none;
    border-radius: 4px;
    cursor: pointer; */
    /* Remove the "display: none;" property */
    /* display: inline-block; */
}

@media (max-width:900px){
.rotateView {
flex-direction: column;
align-items: center;
}
}

