@font-face {
    font-style: bold;
    src: url("assets/fonts/MontserratMedium.ttf");
    font-family: "Montserrat";
    font-weight: 500;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/PoppinsMedium.ttf");
    font-family: "Poppins";
    font-weight: 500;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/PoppinsBold.ttf");
    font-family: "Poppins";
    font-weight: 700;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/RubikRomanLight.ttf");
    font-family: "Rubik";
    font-weight: 300;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/RubikRomanMedium.ttf");
    font-family: "Rubik";
    font-weight: 500;
}

@font-face {
    font-style: normal;
    src: url("assets/fonts/RubikRomanRegular.ttf");
    font-family: "Rubik";
    font-weight: 400;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/RubikRomanSemiBold.ttf");
    font-family: "Rubik";
    font-weight: 600;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/PoppinsLight.ttf");
    font-family: "Poppins";
    font-weight: 300;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/PoppinsSemiBold.ttf");
    font-family: "Poppins";
    font-weight: 600;
}

@font-face {
    font-style: normal;
    src: url("assets/fonts/PoppinsRegular.ttf");
    font-family: "Poppins";
    font-weight: 400;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/InterSemiBold.ttf");
    font-family: "Inter";
    font-weight: 600;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/RubikRomanBold.ttf");
    font-family: "Rubik";
    font-weight: 700;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/InterBold.ttf");
    font-family: "Inter";
    font-weight: 700;
}

@font-face {
    font-style: normal;
    src: url("assets/fonts/DMSansRegular.ttf");
    font-family: "DM Sans";
    font-weight: 400;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/Commissioner.ttf");
    font-family: "Commissioner";
    font-weight: 400;
}

@font-face {
    font-style: bold;
    src: url("assets/fonts/InterMedium.ttf");
    font-family: "Inter";
    font-weight: 500;
}