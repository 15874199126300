.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  white-space: nowrap;
  z-index: 999;
  top: -30px;
  /* Adjust this value for vertical positioning */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}