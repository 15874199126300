:root {
    --bluegray_52: #efeff1;
    --bluegray_51: #ecf2f8;
    --bluegray_50: #eef0f5;
    --blue_A401: #2d81ff;
    --blue_A400: #2170fa;
    --red_400: #ce5347;
    --amber_A100: #f7df76;
    --yellow_800: #f5a722;
    --pink_500: #ff2f61;
    --amber_500: #ffc610;
    --gray_800: #504a4c;
    --gray_801: #4c4c4c;
    --cyan_200: #8bf2d5;
    --deep_purple_50: #efefff;
    --bluegray_401: #769ca8;
    --bluegray_400: #638c9e;
    --cyan_51: #d8f0f9;
    --blue_102: #c8d3fb;
    --blue_100: #cadaf7;
    --cyan_50: #e4ffff;
    --blue_101: #cae6fd;
    --bluegray_57: #edecf5;
    --bluegray_56: #eeeff0;
    --indigo_601: #4c449e;
    --bluegray_55: #eff0f1;
    --bluegray_54: #f1f1f3;
    --bluegray_53: #f0f0f2;
    --indigo_600: #4338b8;
    --gray_116: #f6f6fa;
    --gray_114: #f1f1ff;
    --deep_orange_A200: #ff5e45;
    --gray_115: #f0f0ff;
    --light_blue_A400: #01a8ff;
    --light_blue_601: #009cde;
    --light_blue_600: #03a0d2;
    --red_300: #ee6a5f;
    --blue_500_87: #1a8ff787;
    --gray_112: #f3f3ff;
    --green_A200: #6ae1c1;
    --gray_113: #f2f2ff;
    --gray_110: #f5f5f6;
    --gray_111: #f2f2f4;
    --teal_400: #2a8c96;
    --teal_401: #38a888;
    --gray_905: #151516;
    --gray_906: #111111;
    --gray_903: #071633;
    --gray_904: #111029;
    --blue_500_7f: #1a8ff77f;
    --gray_907: #0f172a;
    --gray_105: #f2f2f3;
    --gray_106: #f7f7f8;
    --gray_104: #f5f7fa;
    --gray_901: #101821;
    --gray_109: #f3f3f5;
    --gray_902: #181b1a;
    --gray_107: #f6f6f7;
    --gray_900: #050f2b;
    --gray_108: #f4f4f5;
    --orange_500: #ff9800;
    --gray_101: #f6f1ff;
    --gray_102: #f1f5f9;
    --gray_100: #f5f1ff;
    --bluegray_300: #94a3b8;
    --gray_900_3d: #140e343d;
    --blue_50_4c: #edfaff4c;
    --cyan_900: #005a75;
    --indigo_700: #355083;
    --indigo_701: #433b9a;
    --indigo_63: #e7ecfb;
    --deep_purple_801: #37269c;
    --deep_purple_800: #3a3195;
    --indigo_61: #e4e4ff;
    --indigo_62: #e4e3f1;
    --indigo_60: #e5e5ff;
    --green_500: #58a942;
    --gray_600: #6b6b6b;
    --gray_63: #f8f8ff;
    --gray_601: #737373;
    --gray_62: #f9f9ff;
    --gray_65: #f6f6ff;
    --gray_64: #f7f7ff;
    --gray_66: #f4f4ff;
    --bluegray_200_26: #b5b7c026;
    --gray_61: #fafaff;
    --gray_60: #fbfbff;
    --bluegray_600: #4d5986;
    --gray_59: #fafafb;
    --bluegray_100_7f: #cbcbcb7f;
    --cyan_A400: #01f1e3;
    --deep_purple_900: #0000ad;
    --deep_purple_901: #312890;
    --blue_A100: #7eb2ea;
    --light_blue_401: #29a9e1;
    --light_blue_400: #29b4e7;
    --deep_purple_100: #cfcfee;
    --green_400: #61c454;
    --deep_purple_101: #d2d0e7;
    --bluegray_50_7f: #eef0f57f;
    --black_900: #000000;
    --indigo_56: #eaeaff;
    --indigo_57: #e9e9ff;
    --indigo_54: #ececff;
    --gray_900_05: #00113b05;
    --indigo_55: #ebebff;
    --indigo_52: #e7e9f1;
    --indigo_53: #ededff;
    --indigo_50: #e4ecf7;
    --amber_600: #f9b50b;
    --indigo_904: #1c0a76;
    --blue_700_87: #356fd887;
    --indigo_51: #e2e8f0;
    --bluegray_900_14: #38323e14;
    --indigo_905: #1c0a75;
    --orange_300: #f5bd4f;
    --indigo_102: #c0bedd;
    --white_A700_00: #ffffff00;
    --indigo_103: #c9c7e2;
    --indigo_100: #c0cad9;
    --indigo_101: #b9bee4;
    --bluegray_500: #636c95;
    --indigo_902: #092a6f;
    --indigo_903: #003087;
    --indigo_900: #173361;
    --cyan_600_b2: #01b1b8b2;
    --indigo_58: #e8e8ff;
    --indigo_59: #e6e6ff;
    --green_300: #68ee75;
    --bluegray_111: #cccdd2;
    --bluegray_110: #cdced2;
    --cyan_600_33: #01b1b833;
    --deep_purple_200: #aeabd4;
    --bluegray_119: #cfd0d4;
    --bluegray_118: #d1d1d6;
    --bluegray_117: #d2d2d7;
    --bluegray_116: #d4d4d9;
    --bluegray_115: #d5d5d9;
    --bluegray_114: #d8d8dc;
    --bluegray_113: #c9cacf;
    --teal_900: #003f52;
    --bluegray_112: #cacbd0;
    --light_blue_A700: #017fe4;
    --deep_purple_A201: #6458f7;
    --indigo_900_19: #1a247b19;
    --gray_408: #c3c4ca;
    --deep_purple_A200: #8440ff;
    --gray_409: #c2c3c9;
    --teal_A700: #00c7b6;
    --gray_402: #c4c5ca;
    --amber_900: #ff7900;
    --gray_403: #c1c2c8;
    --gray_400: #bfbfbf;
    --amber_901: #ff7800;
    --gray_401: #c6c7cd;
    --gray_406: #c6c7cc;
    --gray_407: #c5c6cb;
    --blue_500: #1a8ff7;
    --gray_404: #c7c8cd;
    --gray_405: #c4c5cb;
    --bluegray_122: #c8c9ce;
    --bluegray_121: #cbccd1;
    --bluegray_120: #cecfd3;
    --black_900_0c: #0000000c;
    --indigo_201: #a5a2cf;
    --bluegray_800: #373858;
    --indigo_202: #b0b0de;
    --black_900_11: #00000011;
    --indigo_200: #9c98ca;
    --indigo_A700: #4141e2;
    --black_900_19: #00000019;
    --gray_52: #f9f5ff;
    --green_201: #99ffa3;
    --green_200: #9fff9f;
    --gray_51: #f7f2ff;
    --gray_54: #fcfcfd;
    --teal_A700_75: #00c7b675;
    --gray_53: #f8f8f9;
    --red_700: #c93544;
    --gray_56: #fcfcfc;
    --gray_55: #fafafa;
    --blue_A700: #2563eb;
    --gray_58: #fbfbfc;
    --gray_57: #f9f9fa;
    --gray_50: #ffeffe;
    --deep_purple_300: #948fc5;
    --red_50: #f8ecf2;
    --blue_A200_87: #437fec87;
    --deep_orange_401: #ed8646;
    --deep_orange_400: #e97b3d;
    --gray_501: #acacac;
    --light_blue_A100_67: #7ad2ff67;
    --gray_500: #a5a5a5;
    --orange_900: #d35600;
    --indigo_300: #7973b7;
    --indigo_301: #827cbb;
    --bluegray_700: #404d61;
    --indigo_302: #8b85c0;
    --indigo_A100: #8d79f6;
    --gray_210: #e6e7e9;
    --teal_301: #39bcbb;
    --teal_300: #42c1b6;
    --green_A700: #24d063;
    --green_A701: #0bad4b;
    --amber_A700: #ffae00;
    --blue_900_87: #184eae87;
    --cyan_700_75: #008bb675;
    --gray_204: #eaebed;
    --white_A705: #fdfdff;
    --gray_205: #e9eaec;
    --white_A704: #fefeff;
    --light_blue_900_7f1: #0a48857f;
    --orange_A201: #d6a243;
    --gray_202: #e7e7e9;
    --orange_A200: #ec953b;
    --gray_203: #edeeef;
    --gray_208: #ebecee;
    --orange_A202: #ffa63e;
    --gray_209: #e8e9eb;
    --gray_206: #e7e8ea;
    --gray_207: #ecedee;
    --gray_200: #e9e8ea;
    --gray_201: #ececee;
    --blue_50: #edfaff;
    --blue_500_75: #1a8ff775;
    --blue_51: #dbeafe;
    --light_blue_900_7f: #1c699e7f;
    --indigo_400: #5e57a8;
    --bluegray_201: #b7b4d8;
    --bluegray_200: #b8c6d7;
    --white_A701: #fdfcff;
    --white_A700: #ffffff;
    --blue_500_6c: #1a8ff76c;
    --white_A703: #fdfdfd;
    --indigo_401: #6760ad;
    --white_A702: #fefefe;
    --indigo_402: #7069b2;
    --gray_314: #dddde0;
    --gray_315: #dbdbdf;
    --gray_312: #e0e0e3;
    --gray_313: #dedee1;
    --gray_316: #dadade;
    --gray_317: #dbdaec;
    --red_101: #fed5db;
    --indigo_A201: #6d67ef;
    --indigo_A200: #6a6ae8;
    --gray_310: #e2e3e6;
    --gray_311: #e0e1e4;
    --red_100: #ffd4dc;
    --gray_309: #e3e4e7;
    --black_900_26: #00000026;
    --blue_605: #1e86d5;
    --gray_303: #d9dade;
    --gray_304: #e1e2e5;
    --gray_301: #e4e4e7;
    --gray_302: #dcdde0;
    --gray_307: #dcdce0;
    --gray_308: #e5e6e8;
    --red_A100: #fc8092;
    --gray_305: #dfdfe2;
    --blue_600: #3a8cdd;
    --gray_306: #e4e5e7;
    --bluegray_100: #d6d6d6;
    --light_blue_300_67: #4fbaf067;
    --indigo_800_7f: #2a2ea37f;
    --gray_300: #dfe0e3;
    --bluegray_900: #333333;
    --white_A700_87: #ffffff87;
    --bluegray_108: #d3d3d8;
    --bluegray_107: #d6d6da;
    --bluegray_106: #d7d7db;
    --bluegray_105: #d9d9dd;
    --bluegray_104: #cecfd4;
    --bluegray_103: #d1d2d6;
    --bluegray_102: #d4d5d9;
    --bluegray_101: #cbd5e1;
    --bluegray_904: #092954;
    --cyan_700: #008bb6;
    --bluegray_903: #28282f;
    --bluegray_902: #1c2632;
    --indigo_500: #3362ab;
    --bluegray_901: #252f3e;
    --bluegray_109: #d0d1d5;
    --indigo_501: #554da3;
}