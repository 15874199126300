@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #f8f8f9;
}

/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    --tw-ring-color: var(--primary-color);
    border-color: var(--primary-color);
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0px;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
}

input:focus, textarea:focus {
    outline: none !important;
    outline-offset: 0 !important;
    --tw-ring-offset-width: none !important;
}

li:focus-visible {
    outline: none;
}

li:hover {
    cursor: pointer;
}

.tab-panel--selected {
    animation-name: tabPanel;
    animation-duration: 300ms;
    animation-timing-function: linear;
}

@keyframes tabPanel {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Input style */
.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.input-container label {
    position: absolute;
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

