.swagger-ui {
  background-color: #f0f0f0; /* Your custom background color */
margin: 25px;
padding: 10px;
border-radius: 20px;
 
}

.swagger-ui .opblock-summary-description {
  font-size: 16px; /* Your custom font size */
}
